import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import logo from '../assets/images/Logo.png';
import presantationchart from '../assets/images/presention-chart.png';
import uploadphotos from '../assets/images/UploadPhoto.png';
import Share from '../assets/images/Share.png';
import Settings from '../assets/images/Settings.png';
import profilecircle from '../assets/images/profile-circle.png';
import 'bootstrap/dist/css/bootstrap.css';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Modal, Progress, Tooltip } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import facebook from '../assets/images/facebook.png';
import whatsapp from '../assets/images/whatsapp.png';
import instagram from '../assets/images/instagram.png';
import mail from '../assets/images/mail.png';
import copyLink from '../assets/images/copyLink.png';
import closeShare from '../assets/images/close_share.png';
import stopcircle from '../assets/images/stop-circle.png';
import pausecircle from '../assets/images/pause-circle.png';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {
    InnerStyledHeader, Logo, ShareIconBox, ShareBoxDiv,
    SocialShare, CloseIcon, PresentChartIcon, UploadPhotoIcon, ShareIcon, SetingsIcon, ProfileIcon,
    ModalPassword, UploadStatus, UploadWrap, UploadProgress, UploadBtn, PausePlayIcon
} from './styles/InnerStyledHeader';
import { Collapse } from 'antd';


const InnerHeader = () => {
    const { Panel } = Collapse;
    function callback(key) {
        console.log(key);
    }
    let location = useLocation()
    let pageurl = location.pathname
    const { t } = useTranslation();
    let history = useHistory();

    const settingpage = () => {
        history.push("/settings");
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isToggle, setToggle] = useState(true);
    const [showStatus, setStatus] = useState(false);
    const [open, setOpen] = useState(false);
    const [play, setPlay] = useState(false);

    const showStatusBox = () => {
        setStatus(!showStatus)
    };
    const hideStatusBox = () => {
        setStatus(false);
        setPlay(false);
    };
    const showModal = () => {
        setIsModalVisible(true);
        setToggle(!isToggle)
    };
    const handleOk = () => {
        setIsModalVisible(false);
        setToggle(!isToggle)
    };
    const closeSocialbar = () => {
        setToggle(true)
    };
    const Openhumberger = () => {
        setOpen(!open);
    }
    const Overlay = () => {
        setOpen(false);
    }
    const btnPause = <span>Pause</span>;
    const btnCancel = <span>Cancel Uploading</span>;


    return (
        <>
            <InnerStyledHeader>
                <Navbar bg="light" expand="lg">
                    <Container>
                        <Navbar.Brand href="/">
                            <Logo src={logo} alt="logo" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={Openhumberger} />
                        {pageurl === "/settings" ? '' :
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav>
                                    <Nav.Link>{t('CEREMONY')}</Nav.Link>
                                    <Nav.Link>{t('FEAST')}</Nav.Link>
                                    <Nav.Link>{t('PARTY')}</Nav.Link>
                                </Nav>
                                <Nav className="header_icons">
                                    <Nav.Link>
                                        <PresentChartIcon src={presantationchart} alt='presantationchart' />
                                    </Nav.Link>
                                    <Nav.Link className='link_i'>
                                        <UploadPhotoIcon src={uploadphotos} alt='uploadphotos' onClick={showStatusBox} />
                                    </Nav.Link>
                                    <Nav.Link className='link_i share'>
                                        <ShareIcon src={Share} alt='Share' onClick={handleOk} />
                                        <ShareIconBox visibility={isToggle} >
                                            <ShareBoxDiv>
                                                <SocialShare src={facebook} alt='facebook' />
                                            </ShareBoxDiv>
                                            <ShareBoxDiv>
                                                <SocialShare src={instagram} alt='instagram' />
                                            </ShareBoxDiv>
                                            <ShareBoxDiv>
                                                <SocialShare src={whatsapp} alt='whatsup' />
                                            </ShareBoxDiv>
                                            <ShareBoxDiv>
                                                <SocialShare src={mail} alt='mail' />
                                            </ShareBoxDiv>
                                            <ShareBoxDiv>
                                                <SocialShare src={copyLink} alt='copylink' />
                                            </ShareBoxDiv>
                                            <ShareBoxDiv onClick={closeSocialbar}>
                                                <CloseIcon src={closeShare} alt='close' />
                                            </ShareBoxDiv>
                                        </ShareIconBox>
                                    </Nav.Link>
                                    <Nav.Link className='link_i'>
                                        <SetingsIcon src={Settings} alt='Settings' onClick={settingpage} />
                                    </Nav.Link>
                                    <Nav.Link className='link_i'>
                                        <ProfileIcon src={profilecircle} alt='profilecircle' />
                                    </Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        }
                        <div className={open ? 'openMenu' : ''} id="overlayMenu" onClick={Overlay}></div>
                        <div id="photomobhead">
                            <Collapse onChange={callback} className='collapswarp'>
                                <Panel header="Album Scenes" key="1" className='menulist'>
                                    <ul>
                                        <li><Link to="/photographer" onClick={Overlay}>Ceramony</Link></li>
                                        <li><Link to="/photographer" onClick={Overlay}>Feast</Link></li>
                                        <li><Link to="/photographer" onClick={Overlay}>Party</Link></li>
                                    </ul>
                                </Panel>
                                <Panel header="share album" key="2" className='socialicons'>
                                    <ul>
                                        <li><img src={facebook} alt="Snow" /></li>
                                        <li><img src={instagram} alt="Snow" /></li>
                                        <li><img src={whatsapp} alt="Snow" /></li>
                                        <li><img src={mail} alt="Snow" /></li>
                                        <li><img src={copyLink} alt="Snow" /></li>
                                    </ul>
                                </Panel>
                            </Collapse>
                            <div className="row">
                                <div className="column" onClick={showStatusBox}>
                                    <p>Upload Your Photos</p>
                                    <img src={uploadphotos} alt="Snow" />
                                </div>
                                <div className="column">
                                    <Link to="/settings" onClick={Overlay}>
                                        <p>Album Settings</p>
                                        <img src={Settings} alt="Forest" />
                                    </Link></div>

                                <div className="column">
                                    <p>Accounts</p>
                                    <img src={profilecircle} alt="Mountains" />
                                </div>
                            </div>
                        </div>
                    </Container>
                </Navbar>
                <Modal title={t('THIS_ALBUM_IS_PRIVATE')} visible={isModalVisible} onOk={handleOk} className='albumPassword' okText={t('ENTER_ALBUM')}>
                    <ModalPassword>
                        <label>{t('ENTER_ALBUM_PASSWORD')}</label>
                        <input type="text" placeholder={t('MODAL_PASSWORD')} />
                    </ModalPassword>
                </Modal>

                <UploadStatus bottom={showStatus}>
                    <UploadWrap>
                        <p>{t('UPLOADING_PHOTOS')}</p>
                        <UploadBtn>
                                <button onClick={() => setPlay(!play)} className='pauseicon'>
                                    <Tooltip placement="top" title={btnPause}>
                                       {play ? <PlayCircleOutlined/> :
                                       <PausePlayIcon src={pausecircle} alt='pausecircle'/>
                                       } 
                                    </Tooltip>
                                </button>
                            <button className='cancel_btn' onClick={hideStatusBox}>
                                <Tooltip placement="top" title={btnCancel}>
                                    <PausePlayIcon src={stopcircle} alt='stopcircle' />
                                </Tooltip>
                            </button>
                        </UploadBtn>
                    </UploadWrap>
                    <UploadProgress>
                        <span>{t('FOURTEEN')}</span>
                        <Progress percent={14} size="small" />
                    </UploadProgress>
                </UploadStatus>
            </InnerStyledHeader>
        </>
    );
};
export default InnerHeader;