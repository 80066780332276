import styled,{ createGlobalStyle,keyframes} from "styled-components";
import IBMPlexSansHebrewRegular from '../../assets/font/IBMPlexSansMedium.ttf';
import LinotypeDidotRoman from '../../assets/font/LinotypeDidotRoman.ttf';
import dropdownAro from '../../assets/images/ArrowDown.png';
import dropdownwhite from '../../assets/images/arrowDwonWhite.png';
import calender from '../../assets/images/calander_dark.png';


const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: BMPlexSansHebrewRegular;
  src: local('BMPlexSansHebrewRegular'), local('BMPlexSansHebrewRegular'),
  url(${IBMPlexSansHebrewRegular}) format('ttf'),
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:  Didot LT Pro;
  src: local(' LinotypeDidotRoman'), local('LinotypeDidotRoman'),
  url(${LinotypeDidotRoman}) format('ttf');
  font-weight: 300;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,700;1,700&display=swap');
*{
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
  }
  #root select , #root input[type="date"]{
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent !important;
    background-image: url(${dropdownAro});  !important;
  background-position: calc(100% - 10px) center;
    background-size: 18px auto;
    background-repeat: no-repeat;
    color: #283845;
  }
  #root select.select_cate_bx{
    background-color: #283845 !important;
    background-image: url(${dropdownwhite});  !important;
  background-position: calc(100% - 10px) center;
    background-size: 18px auto;
    background-repeat: no-repeat;
    color: #fff;
    min-width: 100px;
  }
  #root input[type="date"]{
    background-image: url(${calender});  !important;
    background-position: calc(100% - 10px) center;
    background-size: 20px auto;
  }
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {    
      background-size: 20px auto;
      -webkit-appearance: none;
      opacity: 0;
  }

  #root input::-webkit-input-placeholder {
    color: #283845;
    opacity: 1;
  }
  
  #root input:-ms-input-placeholder { 
    color: #283845;
    opacity: 1;
  }
  
  #root input::placeholder {
    color: #283845;
    opacity: 1;
  }
  .react-multiple-carousel__arrow{
    z-index: 99;
}
#root .ant-picker{
  display: block;
  font-size: 0;
  border: 0;
  padding: 0;
  background: transparent;
}
.ant-picker-input{
  margin-top: 5px;
}
#root  .ant-picker:hover, #root .ant-picker-focused{
  border-color: transparent;
  border: 0;
  box-shadow: none;
}
#root .ant-picker-input > input{
  margin: 0;
}
#root .ant-picker-suffix {
  position: absolute;
  right: 0;
  width:30px;
  height: 100%;
  background-image: url(${calender});  !important;
    background-position: calc(100% - 10px) center;
    background-size: 20px auto;
    background-repeat: no-repeat;
  .anticon{
    display: none;
  }
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

  .progressBar{
    position: relative;
    margin-top: 50px; 
    @media (min-height: 700px){
      margin: 80px 0 0;
    }
    .ant-progress{
      display: flex;
      flex-direction: column-reverse;
      width:100%;
      justify-content: center;
      span.ant-progress-text {
        color: rgb(248, 166, 111);
        font-size: 40px;
        position: relative;
        top: 0px;
        bottom: 0px;
        margin-bottom: 15px;
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }
    }
    
  }


  body .ant-tooltip.ant-tooltip-placement-top  .ant-tooltip-inner{
    background: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
  }
  body .ant-tooltip.ant-tooltip-placement-top  .ant-tooltip-arrow{
    display: none;
  }
  body .ant-tooltip.ant-tooltip.ant-tooltip-placement-top  .ant-tooltip-inner > span{
     background: rgba(0,0,0,0.7);
     display:inline-flex;
     align-items: center;
     padding: 8px 10px;
     position: relative;
     border-radius: 4px;
     &:after{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 5px;
      height: 5px;
      margin: auto;
      background-color: rgb(40 19 19 / 64%);
      content: '';
      pointer-events: auto;
      top: 100%;
      box-shadow: 3px 3px 7px rgb(0 0 0 / 7%);
      left: 20%;
      transform: translateX(-50%) rotate(45deg);
      z-index: 555;
      margin: auto;
      box-shadow: 0 0 5px rgba(255 , 255 , 255 , 0.7);
     }
  }
  body .ant-tooltip.ant-tooltip  .ant-tooltip-inner > span.whiteTool{
    color: #283845;
      background: #fff;
      box-shadow: 0 0 5px rgb(0 0 0 / 18%);
    &:after{
      background-color: #fff;
    }
  }  
  body .ant-tooltip .ant-tooltip-inner > span .anticon{
    margin-right:5px;
    display: inline-block;
  } 
  body{
    background-color:#FFF5EE !important;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #283845 !important;
    padding-top: 70px;

    .navbar{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 999;
    }
  }
`

export const size = {
  mobileS: '360px',
  mobileM: '580px',
  mobileB: '767px',
  tablet:  '768px',
  tabletM: '980px',
  laptop:  '1024px',
  desktop: '1024px',
  lgdesktop:  '1300px',
  screenHtsm: '1500px',
  screenHtM:  '1366px',
  screenHt700: '700px'
}
export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileB: `(max-width: ${size.mobileB})`,
  tablet: `(max-width: ${size.tablet})`,
  minTablet: `(min-width: ${size.tablet})`,
  tabletM: `(max-width: ${size.tabletM})`,
  laptop: `(max-width: ${size.laptop})`,
  laptop: `(max-width: ${size.laptop})`,
  desktop: `(min-width: ${size.desktop})`,
  mdDesk: `(max-width: ${size.lgdesktop})`,
  screenHtsm: `(max-width: ${size.screenHtsm})`,
  lgScreen:   `(min-width: ${size.screenHtsm})`,
  screenHtM: `(max-width: ${size.screenHtM})`,
  screenHt700: `(min-height: ${size.screenHt700})`,
};
export const Row = styled.div`
  width: 100%;
  display: flex;
  margin: 0px auto;
@media ${device.tablet} { 
    flex-wrap: wrap;
  }
`
export const RightSec = styled.div`
height: calc(100vh - 120px);
background: white;
text-align: center;
box-shadow: 0px 20px 30px rgb(0 0 0 / 10%);
border-radius: 24px;
width: 100%;
padding: 0px 10px;
max-width: 550px;
margin-left: auto;
display: flex;
align-items: center;
justify-content: center;
padding: 80px 42px;
min-height: 550px;
@media ${device.screenHtsm}{
  max-width: 450px;
  padding: 60px 42px;
 } 
  @media  ${device.screenHtM}{
    max-width: 400px;
    padding: 60px 25px;
  }
@media ${device.mobileB} { 
  max-width: 100%;
  width: 100%;
  right: 0;
  left: 0px;
  margin: auto;
  position: static;
  transform: none !important;
  height: auto;
  padding: 60px 15px;
}
@media ${device.lgdesktop} {
   h1, h2{
    font-size:40px;
   }
}
`
export const Rightwrap= styled.div`
width: 100%;
animation: fadeInAnimation ease 1s;
animation-iteration-count: 1;
animation-fill-mode: forwards;
max-width: 520px;
margin-left: auto;
margin-right: auto;
@media ${device.mobileB} { 
  max-width: 380px;
}
@media ${device. mobileM} { 
  max-width: 300px;
}
span.anticon.anticon-check-circle {
  font-size: 40px !important;
}

p{
  color: #283845;
  text-decoration: underline;
  font-size: 15px;
  margin: 12px 0px 0px 0px;
  cursor: pointer;
  font-family: IBMPlexSansHebrewBold;
  transition: color 150ms;
  &:hover{
    color: #f8a66f;
  }
}
`
export const UploadImg = styled.img`
max-width: 100px;
 @media ${device.tablet} { 
    max-width: 60px;
  }
`
export const Back = styled.div`
text-decoration-line: underline;
font-size: 15px;
padding-top: 8px;
position: relative;
padding-left: 12px;
cursor: pointer;
transition: color 150ms;
text-align: left;
&:hover {
    color: #f8a66f;
}
&:before {
    content: "<";
    position: absolute;
    left: 0;
    font-family: monospace;
    margin-top: 2px;
}
`
export const Navbar = styled.div`
width: 100%;
background-color: #FFF5EE !important;
 @media ${device.tablet} { 
  width: 100%;
  }
`
export default GlobalStyle
