import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
  <Suspense fallback={<span>Loading...</span>}>
    <App />
  </Suspense>
</React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
