import React, { useState } from 'react';
import trash from '../../assets/images/trash.png'
import { Modal, Button } from 'react-bootstrap';
import { settingData } from '../../data';
import { useTranslation } from 'react-i18next';

const Advanced = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const Deletecncl = () => setShow(false);
    const Deleteshow = () => setShow(true);
    const [allowguest, setAllowguest] = useState(false);
    const [reviewgueast,setreviewguest] = useState(false);

    return (
        <div className='albumSettings general_setting'>
            {settingData.map((data) => (
                <form className='genreal' id={data.id}>
                    <div className='field'>
                        <p className='first_l'>{t('HIGHLIGHTS_AMOUNT')}</p>
                        <p className='sec_l'>{t('THE_AMOUNT')}</p>
                        <select>
                            <option>{data.highlightsType}</option>
                        </select>
                    </div>
                    <div className='field checkboxed'>
                        <input type="checkbox" placeholder={data.allowGuestUploads} value={allowguest} onChange={(e)=> setAllowguest(true)}/>
                        <span class="checkmark"></span>
                        <div className='labels_check'>
                            <p className='first_l'>{t('ALLOW_GUEST_UPLOADS')}</p>
                            <p className='sec_l'>{t('CHECK_THE_BOX')}</p>
                        </div>
                    </div>
                    <div className='field checkboxed'>
                        <input type="checkbox" placeholder={data.reviewGuestUploads} value={reviewgueast} onChange={(e)=> setreviewguest(true)}/>
                        <span class="checkmark"></span>
                        <div className='labels_check'>
                            <p className='first_l'>{t('REVIEW_GUEST_UPLOADS')}</p>
                            <p className='sec_l'>{t('CHECK_THE_BOX_GUEST')}</p>
                        </div>
                    </div>
                    <div className='field'>
                        <p className='first_l'>{t('UPLOADS_QUALITY')}</p>
                        <p className='sec_l'>{t('SET_THE_MAXIMUM_QUAILITY')}</p>
                        <select>
                            <option>{data.uploadQuality}</option>
                        </select>
                    </div>
                    <Button className='trash' onClick={Deleteshow}>
                        <img src={trash} alt="ceramony" />{t('DELETE_ALBUM')}
                    </Button>
                    <Modal show={show} onHide={Deletecncl} className="delete_popup">
                        <Modal.Header closeButton>
                            <Modal.Title>{t('ARE_YOU_SURE')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className='delete_txt'>{t('ALL_OF_YOUR_DATA')}</p>
                            <div className='delete_in'><label>{t('DELETE_YOUR_ALBUM')}</label>
                                <input type="text" placeholder='Enter your album name' />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={Deletecncl}>
                                {t('CANCEL')}
                            </Button>
                            <Button variant="dangrous" onClick={Deletecncl}>
                                {t('DELETE')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className='settings_btns'>
                        <button className="bg-gray" disabled={!allowguest || !reviewgueast}>{t('CANCEL')}</button>
                        <button  disabled={ !allowguest || !reviewgueast}>{t('SAVE_CHANGES')}</button>
                    </div>
                </form>
            ))}
        </div>
    );
};

export default Advanced;