import styled from "styled-components";
import { device } from './Global';

export const Photogallery = styled.div`
padding-top: 40px;
`
export const Photogalleryinner = styled.div`
width: 100%;
display: flex;
justify-content: center;
button {
    font-size: 18px;
    background: transparent;
    border: none;
    padding: 0px 20px;
    cursor: pointer;
    line-height: 23.4px;
    @media ${device.tabletM} { 
        font-size: 16px;
        line-height: 20.8px;
      }
      @media ${device.mobileM} { 
        font-size: 14px;
        line-height: 18.8px;
        padding: 0px 12px;
      }
    p{
    color: #283845;
    border-bottom: 2px transparent;
    margin: 0;
    padding-bottom:15px;
    }
}
`
export const Guest_inner = styled.div`
text-align: center;
background: #fff;
padding-top: 30px;
h1{
    font-weight: 400;
    text-transform: uppercase;
    color: #283845;
    font-size: 30px;
    line-height: 39px;
    margin-bottom:30px;
    @media ${device.tablet} { 
        font-size: 20px;
        line-height: 26px;
        margin-bottom:40px;
        font-weight: 700;
        margin-bottom:25px;
      }
}
`
export const Gallery_btns = styled.div`
padding: 8px 0px 0;
text-align: center;
margin-top: 10px;
&.photofind {
    justify-content: center;
}

@media ${device.mobileM} { 
    &.photofind {
        flex-direction: unset;
        flex-wrap: wrap;
        justify-content: center;
        max-width:400px;
        padding: 0 10px;
        margin-left: auto;
        margin-right: auto;
    }
      button:first-child{
        order: 2;
        margin-bottom: 10px;
    }
}
button{
    background: #F8A66F;
    border: none;
    padding: 12px 32px;
    color: #fff;
    cursor: pointer;
    margin-right: 10px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight:normal;
    font-family: IBMPlexSansHebrewBold;
    letter-spacing: 0.05px;
    &:last-child{
        margin-right: 0;
    }
    @media ${device.tablet} { 
        margin-top:0px
    }
    @media ${device.mobileM} { 
        font-size: 16px;
        line-height: 20.8px;
        margin: 20px 0 0;
        max-width: 95%;
        padding: 10px 20px;
      }
     
}
@media ${device.tablet} { 
    font-size: 16px;
    padding: 4px 0px !important;
    margin-top: 19px;
    display: flex;
    flex-direction: row;
  }
`
export const Slider_wrappper = styled.div`
margin: auto;
width: 100%;
overflow-x: unset!important;
max-width: 1000px;
position: relative;
`
export const Slider_row = styled.div`
position: relative;
`
export const GuestCarousel = styled.div`
@media ${device.mobileB} { 
    padding: 0 !important;
  }

`

export const Slider_sideicon = styled.div`
position: absolute;
transform: translateY(-50%)!important;
z-index: 5;
@media ${device.mobileB} {
    top: 100% !important;
    transform: none !important;
    margin-top: 10px;
    padding: 0 5px;
    .txtUnderline{
       text-decoration: underline;
        display: inline-block;
    }
}
&:first-child{
    left:0;
    top:55%;
    @media ${device.tabletM} {
        left: 10px;
    }
}
&:last-child{
    right:0;
    top:55%;
    @media ${device.tabletM} {
        right: 5px;
        left: auto;
    }
    @media ${device.mobileB} { 
    img{
        margin-right: 5px;
        max-height:25px;
    }
 }   
}
p{
    background: #69747d;
    color: #fff;
    left: -90px;
    padding: 5px 9px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%)!important;
    transform: translateY(-50%)!important;
    &:before{
        border-color: transparent #69747d transparent transparent;
    border-style: solid;
    border-width: 8px 10px 8px 0;
    content: "";
    height: 0;
    position: absolute;
    right: -10px;
    top: 9px;
    -webkit-transform: rotate(
180deg);
    transform: rotate(
180deg);
    width: 0;
    }
}
`
export const Chng_selfieimg = styled.div`
position: relative;
&:before {
    content: "8";
    position: absolute;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    bottom: 2px;
    left: 8px;
}
img{
    width: 100%;
    max-width: 115px;
    border: 4px solid #F8A66F;
    @media ${device.tablet} { 
    max-width: 70px;
      }
}
`
export const ChangeSelfy_btn = styled.div`
  button{
    background: #F8A66F;
    border: none;
    padding: 12px;
    color: #fff;
    margin-left: 10px;
    cursor: pointer;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.05em;
  }
`
export const Gallery_block = styled.div`
background: #fff;
padding: 40px 0px 25px 0px;
margin-top: 0px;
`
export const Footer_btn = styled.button`
position: absolute;
    right: 30px;
    border: none;
    color: #283845;
    border-bottom: 1px solid #283845;
    font-size: 18px;
    line-height: 1.1;
    background: none;
    margin-top: 10px;
    /* text-decoration: underline; */
    letter-spacing: 0;
@media ${device.mobileB} { 
    display: none;
      }
`
export const PhotographerStyled = styled.div`
padding-top: 40px;
`
export const PhotoGrapherInner = styled.div`
width: 100%;
display: flex;
justify-content: center;
button{
    font-size: 18px;
    background: transparent;
    border: none;
    padding: 0px 20px 20px 20px;
    cursor: pointer;
    color: #283845;
    margin-bottom: 0;
    padding-bottom: 15px;
}
.active_tabs {
    border-bottom: 2px solid;
    margin: 0px;
    font-weight: 600;
}
`
export const PhotoGrapherBlock = styled.div`
background: #fff;
padding: 40px 0px 25px 0px;
margin-top: 0px;
`
export const ChangeSelfy = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 40px 0px 50px 0px;
text-align: center;
`
export const ChangeSelfyblock = styled.div`
width: 100%;
max-width: 115px;
border: 4px solid #F8A66F;
`
export const ChangeSelfyImg = styled.img`
position: relative;

&:before{
content: "8";
position: absolute;
color: #fff;
font-weight: 600;
font-size: 18px;
bottom: 2px;
left: 8px;
   }
`
export const ChangeSelfyBtn = styled.div`
margin-left: 10px;
button{
background: #F8A66F;
border: none;
padding: 12px 32px;
color: #fff;
margin: 10px;
cursor: pointer;
font-size: 18px;
text-transform: uppercase;
font-weight: bold;
letter-spacing: 0.05em;
}
`
export const PhotographerGallery = styled.div`
text-align: center;
`
export const GuestBlock = styled.div`
text-align: center;
`
export const GuestRow = styled.div`
display: flex;
flex-wrap: wrap;
`
export const GuestItem = styled.div`
width: 8.3333%;
padding: 10px;
img{
    width: 100%;
}
`
export const GalleryBlockBtn = styled.div`
text-align: center;
display: flex;
align-items: center;
justify-content: center;
padding: 40px 0px 50px 0px;
button{
background: #F8A66F;
border: none;
padding: 12px 32px;
color: #fff;
margin: 10px;
cursor: pointer;
font-size: 18px;
text-transform: uppercase;
font-weight: bold;
letter-spacing: 0.05em;
}
p{
font-size: 18px;
text-decoration: underline;
padding-top: 5px;
cursor: pointer;
transition: color 150ms;
}
`
export const ChangeselfyDiv = styled.div`
position: relative;
&:before{
    content: "8";
    position: absolute;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    bottom: 2px;
    left: 8px;
}
img{
    border: 4px solid #F8A66F;
    margin-right: 10px;
    width: 100%;
    max-width: 115px;
}
`
// guestlist
export const Gauest_row_s = styled.div`
display: flex;
flex-wrap: wrap;
margin-top: 40px;
margin-left: -5px;
margin-right: -5px;
`
export const Gauest_item = styled.div`
width: 8.3333%;
padding: 5px;
position:relative;
.guestChecklist{
    position: relative;
    padding: 0;
    label{
        display: block;
    }
}
.borderBX{
    border: 3px solid transparent;
    display: block;
}
.checkBX {
    position: absolute;
    top:0;
    left: 0;
    bottom:0;
    right: 0;
    opacity: 0;
    width: 100%; height: 100%;
    z-index: 1;
}
.checkBX:hover + .showCntent .guestPhoto{
    filter: grayscale(0) !important;
}

.checkBX:checked + .showCntent{
    .borderBX{
        border: 3px solid #F8A66F;
        filter: grayscale(0);
        .guestPhoto{
            filter: grayscale(0);
        } 
    }
}
.guestPhoto{
    width:100%;
    filter: grayscale(80%);
    transition: all 500ms ease;
    &:hover{
        filter: grayscale(0) !important;
    }
}
&.fadein {
    
    .borderBX{
        border: 3px solid #F8A66F;
    }
    .guestPhoto{
        width:100%;
        filter: grayscale(0);
    } 
}
@media ${device.tabletM} { 
    padding: 8px;
    width: 12.3333%;
  }

  @media ${device.mobileM} { 
    padding: 4px;
    width: 25%;
  }
`

export const Guest_wrap = styled.div`
position:relative;
&.fadeinimg{
    .checkBX:checked + .showCntent{
        .borderBX{
            border: 3px solid transparent;
        }
    }
    .borderBX{
        border: 3px solid transparent;
    }
    .guestPhoto{
        filter: grayscale(100%);
        opacity: 0.5 !important; 
    }
    .hidePop{
        display: none ;
    }   
    .showAgn{
        display: block !important;
    }
}
 .guesticonbtn{
position:absolute;
right:3px;
top:3px;
left: auto;
display: inline-block;
width:30px;
padding: 2px;
cursor:pointer;
opacity: 0;
transition: all 500ms ease;
background: #fff;
z-index:11;
text-align: center;
img{
    max-width:80%;
    margin: auto;
}
}
&:hover  .guesticonbtn{
    opacity: 1;
}
.ant-tooltip{
    background: #fff;
    color: #283845;
}
`
// photographer gallery
export const GalleyPhotoItem = styled.div`
width: 100%;
`
export const Rows = styled.div`
  margin: 50px -10px 20px;
  @media ${device.tabletM} {
    margin: 50px -5px 20px;
}
  .pad10{
      padding: 10px;
      @media ${device.tabletM} {
        padding: 5px;
    }
  }
`
// photographertopimage
export const PhotoContent = styled.div`
position: relative;
padding: 30px 0;
overflow: hidden;

@media ${device.tabletM} { 
    padding-top: 0;
    padding-bottom: 10px;
    > div{
        max-width: 100%;
        padding: 0;
    }
}
`
export const CoupleImgSection = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
`
export const CoupleSecInner = styled.div`
width: 50%;
z-index: 5;
position: relative;
z-index: 55;
@media ${device.tabletM} { 
    width: 100%;
  }
img{
    width: 100%;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    @media ${device.tabletM} { 
        width: 100%;
        padding-top: 0;
        border-radius:0;
        box-shadow: none;
      }
}
`
export const CoupleWrap = styled.div`
position: relative;
width: 50%;
z-index: 5;
position: relative;
background: #fff;
min-height: 350px;
display: flex;
flex-wrap: wrap;
align-items: center;
&:before , &:after{
    background: #ffffff;
    content: "";
    height: 100%;
    left: auto;
    position: absolute;
    top: 52%;
    -webkit-transform: translateY(-52%)!important;
    -webkit-transform: translateY(-52%)!important;
    -ms-transform: translateY(-52%)!important;
    transform: translateY(-52%)!important;
    width: 1500%;
    z-index: -1;
    right: 100%;
    min-height: 350px;
}
&:after{
    left:100%;
    right: auto;
}
@media ${device.xldesktop} { 
    min-height: 300px;
    &:before , &:after{
        min-height: 300px;
    }
} 
@media ${device.tabletM} { 
    width: 100%;
    min-height: auto;
    background: transparent;
    &:before , &:after{
        display: none;
    }
}   
`
export const CoupleHead = styled.div`
padding: 50px;

@media ${device.tabletM} { 
    width: 100%;
    padding: 30px 20px 30px;
    text-align: center;
  }
  @media ${device.mobileM} { 
    width: 100%;
    padding: 20px 20px 0;
    text-align: center;
  }
h1{
    margin-top: 0px;
    font-size: 64px;
    font-weight: 700;
    line-height: 76.8px;
    margin-bottom: 0;
    font-family:  'Didot LT Pro';
    margin-bottom: 5px;
    width: 100%;
    @media ${device.xldesktop} { 
        font-size: 50px;
        line-height: 56.8px;
        margin-bottom:10px;
      }
      @media ${device.mobileM} { 
        font-size: 40px;
        line-height: 48px;
      }   
         
}
span{
    color: #F8A66F;
    font-size: 24px;
    display: block;
    line-height: 31.2px;
    font-weight: 400;
    margin-bottom: 15px;
    width: 100%;
    @media ${device.tablet} { 
        font-size: 22px;
        line-height: 24.2px;
        margin-bottom: 10px;
      }
      @media ${device.mobileM} { 
        font-size: 20px;
        line-height: 26px;
      } 
}
P{
    font-size: 18px;
    line-height: 23.4px;
    padding-bottom: 0px;
    margin-bottom: 0px;   
    color: #283845;
    width: 100%;
    @media ${device.tablet} { 
        font-size: 16px;
        line-height: 20.8px;
      }
}
`
