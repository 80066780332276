import React, {useState} from 'react';
import { device } from '../../src/components/styles/Global';
import backToTop from '../assets/images/BackToTop.png';
import styled from "styled-components";

const ScrollBtn = styled.div`
position: fixed; 
right: 0px;
bottom: 40px;
max-width: 60px;
z-index: 999;
cursor: pointer;
@media ${device.mobileM} { 
  right: 10px;
  bottom: 40px;
  max-width: 40px;
}
`
const ScrollIcon = styled.img`
width:100%
`
const ScrollButton = () =>{
  
    const [visible, setVisible] = useState(false)
    
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300){
        setVisible(true)
      } 
      else if (scrolled <= 300){
        setVisible(false)
      }
    };
    
    const scrollToTop = () =>{
      window.scrollTo({
        top: 0, 
        behavior: 'smooth'
      });
    };
    window.addEventListener('scroll', toggleVisible);
    
    return (
      <ScrollBtn onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}} >
        <ScrollIcon src={backToTop}/>
      </ScrollBtn>
    );
  }
  export default ScrollButton;