import styled from "styled-components";
export const Wrapper = styled.div`
width:100%
`
export const MainContainer = styled.div`
width:100%
`
export const Container = styled.div`
max-width: 98%;
margin: auto;
padding: 0px 15px;
width: 100%;
`
export const Container1366 = styled.div`
max-width: 1366px;
margin: auto;
padding: 0px 15px;
width: 100%;
`