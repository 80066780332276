export const albumData = [{
  id: "1",
  name: "John & Wendy’s Wedding",
  date: "10.11.2021",
  photographerName: "Photos By Omri Silver",
  thumbnailUrl: require("../src/assets/images/couple.png"),
  photosAmount: "317",
},
{
  id: "2",
  name: "Iron man’s Party",
  date: "29.04.10",
  photographerName: "Photos By Omri Silver",
  thumbnailUrl: require("../src/assets/images/myalbumspic.png"),
  photosAmount: "13,849",
},
{
  id: "3",
  name: "John & Wendy’s Wedding",
  date: "10.11.2021",
  photographerName: "Photos By Omri Silver",
  thumbnailUrl: require("../src/assets/images/couple.png"),
  photosAmount: "317",
},
{
  id: "4",
  name: "John & Wendy’s Wedding",
  date: "10.11.2021",
  photographerName: "Photos By Omri Silver",
  thumbnailUrl: require("../src/assets/images/couple.png"),
  photosAmount: "317",
}
];

export const guestList = [{
  guestId: "1",
  photoUrl: require("../src/assets/images/off.png")
},
{
  guestId: "2",
  photoUrl: require("../src/assets/images/off_2.png")
},
{
  guestId: "3",
  photoUrl: require("../src/assets/images/off_3.png")
},
{
  guestId: "4",
  photoUrl: require("../src/assets/images/off_4.png")
},
{
  guestId: "5",
  photoUrl: require("../src/assets/images/off_5.png")
},
{
  guestId: "6",
  photoUrl: require("../src/assets/images/off_6.png")
},
{
  guestId: "7",
  photoUrl: require("../src/assets/images/off_7.png")
},
{
  guestId: "8",
  photoUrl: require("../src/assets/images/off_8.png")
},
{
  guestId: "9",
  photoUrl: require("../src/assets/images/off_9.png")
},
{
  guestId: "10",
  photoUrl: require("../src/assets/images/off_10.png")
},
{
  guestId: "11",
  photoUrl: require("../src/assets/images/guest (1).png")
},
{
  guestId: "12",
  photoUrl: require("../src/assets/images/guest (2).png")
},
{
  guestId: "13",
  photoUrl: require("../src/assets/images/guest (3).png")
},
{
  guestId: "14",
  photoUrl: require("../src/assets/images/guest (4).png")
},
{
  guestId: "15",
  photoUrl: require("../src/assets/images/guest (5).png")
},
{
  guestId: "16",
  photoUrl: require("../src/assets/images/guest (6).png")
},
{
  guestId: "17",
  photoUrl: require("../src/assets/images/guest (7).png")
},
{
  guestId: "18",
  photoUrl: require("../src/assets/images/guest (8).png")
},
{
  guestId: "19",
  photoUrl: require("../src/assets/images/guest (9).png")
},
{
  guestId: "20",
  photoUrl: require("../src/assets/images/guest (10).png")
},
{
  guestId: "21",
  photoUrl: require("../src/assets/images/guest (11).png")
},
{
  guestId: "22",
  photoUrl: require("../src/assets/images/guest (12).png")
},
{
  guestId: "23",
  photoUrl: require("../src/assets/images/guest (13).png")
},
{
  guestId: "24",
  photoUrl: require("../src/assets/images/guest (14).png")
},
{
  guestId: "26",
  photoUrl: require("../src/assets/images/guest (15).png")
},
{
  guestId: "27",
  photoUrl: require("../src/assets/images/guest (16).png")
},
{
  guestId: "28",
  photoUrl: require("../src/assets/images/guest (17).png")
},
{
  guestId: "29",
  photoUrl: require("../src/assets/images/guest (18).png")
},
{
  guestId: "30",
  photoUrl: require("../src/assets/images/guest (19).png")
},
{
  guestId: "31",
  photoUrl: require("../src/assets/images/guest (20).png")
},
{
  guestId: "32",
  photoUrl: require("../src/assets/images/guest (21).png")
},
{
  guestId: "33",
  photoUrl: require("../src/assets/images/guest (22).png")
},
{
  guestId: "34",
  photoUrl: require("../src/assets/images/guest (23).png")
},
{
  guestId: "35",
  photoUrl: require("../src/assets/images/guest (24).png")
},
{
  guestId: "36",
  photoUrl: require("../src/assets/images/guest (25).png")
},
{
  guestId: "37",
  photoUrl: require("../src/assets/images/guest (26).png")
},
{
  guestId: "38",
  photoUrl: require("../src/assets/images/guest (27).png")
},
{
  guestId: "39",
  photoUrl: require("../src/assets/images/guest (28).png")
},
{
  guestId: "40",
  photoUrl: require("../src/assets/images/guest (29).png")
}
];

export const photographerPhoto = [{
    photoId: "1",
    photoUrl:require("../src/assets/images/ceramony_1.png"),
  },
  {
    photoId: "2",
    photoUrl:require("../src/assets/images/ceramony_2.png"),
  },
  {
    photoId: "3",
    photoUrl:require("../src/assets/images/ceramony_3.png"),
  },
  {
    photoId: "4",
    photoUrl:require("../src/assets/images/ceramony_7.png"),
  },
  {
    photoId: "5",
    photoUrl:require("../src/assets/images/ceramony_5.png"),
  },
  {
    photoId: "6",
    photoUrl:require("../src/assets/images/ceramony_6.png"),
  },
  {
    photoId: "7",
    photoUrl:require("../src/assets/images/ceramony_4.png"),
  },
  {
    photoId: "8",
    photoUrl:require("../src/assets/images/feast_1.png"),
  },
  {
    photoId: "9",
    photoUrl:require("../src/assets/images/feast_2.png"),
  },
  {
    photoId: "10",
    photoUrl:require("../src/assets/images/feast_7.png"),
  },

  {
    photoId: "11",
    photoUrl:require("../src/assets/images/feast_4.png"),
  },
  {
    photoId: "12",
    photoUrl:require("../src/assets/images/feast_3.png"),
  },
  {
    photoId: "13",
    photoUrl:require("../src/assets/images/feast_6.png"),
  },
  {
    photoId: "14",
    photoUrl:require("../src/assets/images/feast_5.png"),
  },

];

export const settingData = [{
  id: "1",
  name: "John & Wendy’s Wedding",
  category: "Wedding",
  date: "10.11.2021",
  photographerName: "Photos By Omri Silver",
  thumbnailUrl: require("../src/assets/images/couple.png"),
  isPublic: true,
  password: "passward",
  roles: [{
    name: "Ashley Majar",
    emailAddress: "ashley.kajar@gmail.com",
    role: "Admin",
    isPending: false
  },
  {
    name: "Spiderman",
    emailAddress: "peter.parker@marvel.com",
    role: "Admin",
    isPending: false
  },
  {
    name: "Harry Potter",
    emailAddress: "theboywholived@wb.com",
    role: "Admin",
    isPending: false
  },
  ],
  highlightsType: "album type",
  allowGuestUploads: true,
  reviewGuestUploads: true,
  uploadQuality:"best"
}];

export const albumList = [
  {
  albumId: "1",
  name: "John & Wendy’s Wedding",
  photosAmount: 317,
  date: "10.11.21",
  viewsAmount:46,
  thumbnailUrl: require("../src/assets/images/couple.png")
},
{
  albumId: "2",
  name: "Iron Man's Party",
  photosAmount: 13849,
  date: "29.04.10",
  viewsAmount:22427324,
  thumbnailUrl: require("../src/assets/images/myalbumspic.png"),
},
{
  albumId: "3",
  name: "Iron Man's Party",
  photosAmount: 13849,
  date: "29.04.10",
  viewsAmount:22427324,
  thumbnailUrl: require("../src/assets/images/myalbumspic.png"),
},
];