import styled, { keyframes } from 'styled-components';
import {size, device} from '../styles/Global';

// Drophoto
export const Drophoto = styled.div `
width:100%
p{
  color: #283845;
  text-decoration: underline;
  font-size: 15px;
  margin: 12px 0px 0px 0px;
  cursor: pointer;
  font-weight: 600;
  transition: color 150ms;
  @media ${device.screenHtM}{
    margin: 5px 0px 0px 0px;
    font-size: 12px;
  }
}
`
export const Right_sec_head = styled.div `
h1{
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(248, 166, 111, 1);
  font-size: 40px;
  font-weight: 400;
  margin: 20px 0px;
  line-height: 1.5;
  @media ${device.screenHtsm}{
    font-size: 33px;
    margin-bottom: 15px;
  }
  @media ${device.screenHtM}{
    font-size: 32px;
  }
  @media ${device.tablet} { 
    font-size: 35px;
  }   
  @media ${device.mobileM} { 
    font-size: 27px;
  }
  @media ${device.screenHt700}{
    margin: 30px 0;
  }

}
h2{
  color: #283845;
  font-size: 30px;
  line-height: 1.5;
  margin: 0 0 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  @media ${device.screenHtsm}{
    font-size: 24px;
  }
  @media ${device.screenHtM}{
    font-size: 25px;
  }
  @media ${device.tablet} { 
    font-size: 25px;
  }
  @media ${device.mobileM} { 
    font-size: 21px;
  }
}
`
export const Upload_btn = styled.div `
    background: rgba(248,166,111,1);
    border: navajowhite;
    color: #fff;
    padding: 10px 15px;
    -webkit-letter-spacing: 0.05em;
    -moz-letter-spacing: 0.05em;
    -ms-letter-spacing: 0.05em;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: inline-block;
    font-size: 18px;
    cursor: pointer;
    font-weight: normal;
    position: relative;
    input{
      position:absolute;
      top:0;
      left:0;
      height:100%;
      width:100%;
      opacity: 0;
      z-index:55;
      font-size: 0;
      cursor: pointer;
    }
    span{
      padding: 0px 8px;
      font-family: IBMPlexSansHebrewBold;
      letter-spacing: 2px;
    }
    &:hover{
      opacity: 0.8;
    }
    @media ${device.screenHtM}{
      font-size: 16px;
      padding: 10px 20px;
    }
  @media ${device.tablet} { 
    padding: 10px 15px;
  }
  
`
export const gallerybox = styled.div`
margin-top:20px;
@media ${device.tablet} { 
width: 100%;
}
`
// EnterAlbum
export const AddPhoto = styled.div`
position: relative;
text-decoration:underline;
font-size:18px;
cursor: pointer;
text-align: right;
margin-bottom: 0 !important;
margin-left: auto;
@media ${device.screenHtM} { 
    font-size: 16px;
}
@media ${device.mobileB} { 
    font-size: 14px;
}
input{
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    opacity: 0;
    z-index:55;
}
span{
    padding: 0px 8px;
}
`

export const EnterAlbumGalStyled = styled.div`
position: relative;
    max-width: 125px;
    margin: auto;
    @media ${device.screenHtsm}{
        max-width:100px;
    }
    @media ${device.screenHtM}{
        max-width:70px;
    }
    
.first{
    position: absolute;
    left: -55px;
    z-index: 2;
    max-width: 115px;
    top:40%;
    @media ${device.screenHtsm}{
        max-width:85px;
    }
    @media ${device.screenHtM}{
        max-width:75px;
    }
}
.sec{
    position: absolute;
    right: -19px;
    z-index: 2;
    max-width: 55px;
    top:30%;
    @media ${device.screenHtsm}{
        max-width:45px;
    }
    @media ${device.screenHtM}{
        max-width:40px;
    }
}
.third{
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    z-index: 0;
    margin: auto;
    max-width: 100px;
    @media ${device.screenHtsm}{
        max-width:80px;
    }
    @media ${device.screenHtM}{
        max-width:70px;
    }
}
`
export const ProgessBox = styled.div`
margin-top: 50px;
    margin-bottom: 10px;
    position: relative;
    bottom: 0;
    .ant-progress{
        margin-top: 20px;
    }
.ant-progress-outer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .ant-progress-bg {
        background-color: #F8A66F !important;
        height: 8px !important;
        border-radius: 0px !important;
    }
}
.ant-progress-text {
    color: #F8A66F;
    font-size: 40px;
    position: relative;
    top: 0;
    bottom: 0;
    margin-bottom:30px;
    width: 100%;
    text-align: center;
    @media ${device.screenHtsm}{
        font-size: 30px;
    }
}

`
export const TextBar = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding-top: 10px;
padding-bottom: 20px;
font-size: 15px;
p{
    float: none;
    font-size: 18px;
    color: #283845;
    max-width: 69%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 400;
    padding: 0;
    margin: 0;
    text-decoration: none;
    &:hover{
        text-decoration: none;
         
    }
    @media ${device.tablet} { 
        font-size: 16px;
      }
      @media ${device.mobileM} { 
        font-size: 14px;
        max-width: 50%;
      } 
}
`
export const UploadImage = styled.div`
 margin-left: auto;
button{
    float: none;
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
    transition: color 150ms;
    background: transparent;
    border: none;
    text-decoration: none;
        @media ${device.tablet} { 
            font-size: 16px;
          }
          @media ${device.mobileM} { 
            font-size: 14px;
          } 
}
`
export const AlbumDetail = styled.div`
clear: both;
button
{
    background: rgba(248,166,111,1);
    color: #fff;
    width: 100%;
    margin-top: 10px;
    border: none;
    height: 47px;
    text-transform: uppercase;
    font-size: 18px;
    cursor: pointer;
}
button:disabled{
    background: #EAEBEC;
    color: #BFC3C7;
}
h2{
    font-size: 24px;
    line-height: 1.5;
    font-family: IBMPlexSansHebrewBold;
    text-transform: uppercase;
    color: #283845;
    margin: 20px 0;
    @media ${device.screenHtsm}{
        font-size: 22px;
      }
      @media ${device.screenHtM}{
        font-size: 20px;
      }
      @media ${device.mobileM} { 
        font-size: 18px;
      } 
    @media ${device.screenHt700}{
        margin: 30px 0;
      }   
}
`
export const Addcat = styled.div`
clear: both;
text-align: left;
label{
    font-size: 15px;
}
`
export const AlbumInput = styled.div`
display: flex;
align-items: center;
position: relative;
label{
    text-align: left;
    display: block;
}
@media ${device.mobileM} { 
    display:block !important;
    }
input{
    border: 1px solid #283845;
    height: 48px;
    width: 100%;
    padding: 1px 10px;
    margin-top: 5px;
    background: transparent;
}
button{
    background: #283845;
    border: 1px solid #283845;
    height: 48px;
    color: #fff;
    font-family: monospace;
    font-size: 22px;
    width: 35px;
    cursor: pointer;
    margin-top: 5px;
    position:absolute;
    right: 0;
    top: 0;
    &:before{
        content: ">";
    }
    @media ${device.tablet} { 
        display:block;
        }
`
export const ImageUploadStyled = styled.div`
`
export const AddDetails = styled.div`
clear: both;
text-align: left;
`
export const AddcatSection = styled.div`
width: 50%;

select{
    width: 100%;
    border: 1px solid #283845;
    height: 48px;
    width: 100%;
    padding: 1px 10px;
    margin-top: 5px;
    background: transparent;
}
@media ${device.mobileM} { 
    width: 100%;
  } 
`
export const AddDate = styled.div`
padding-left: 6px;
width: 50%;
@media ${device.mobileM} { 
    width: 100%;
    padding: 8px 0 0;
  } 
`

