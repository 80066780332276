import React from 'react';
import MainRouter from './components/MainRouter';
import ScrollButton from './components/ScrollButton'
import { BrowserRouter as Router } from 'react-router-dom'
import {Wrapper} from './components/styles/Wrapper.styled'
import GlobalStyle from './components/styles/Global'

function App() {
  return (
    <Wrapper>
      <GlobalStyle/>
      <Router>
        <MainRouter />
      </Router>
      <ScrollButton />
    </Wrapper>
  );
}
export default App;
