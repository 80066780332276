import styled from "styled-components";
import { size, device } from '../styles/Global';

export const MyalbumStyled = styled.div`
width:100%;
padding-top: 30px;
h1{
padding-left: 0;
font-size: 40px;
padding-bottom: 10px;
text-transform: uppercase;
font-weight: 400;
display: flex;
align-items: center;
color: #283845;
@media ${device.laptop} { 
  font-size: 40px;
}
@media ${device.mobileB} { 
  font-size: 30px;
}
@media ${device.mobileS} { 
  font-size: 22px;
}

span{
  margin-left: auto;
  font-size:16px;
  font-weight: 700;
  text-transform: uppercase;
  display: none;
  transition: all 500ms ease;
  &:hover{
    color: #F8A66F;
  }
  @media ${device.mobileS} { 
    font-size:14px;
  }
  @media ${device.mobileB} { 
    display: flex;
    align-items: center;
  }
  i{
    position: relative;
    width: 25px;
    height: 25px;
    font-style: normal;
    font-size: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 100%;
    font-weight: normal;
    margin-right: 5px;
    @media ${device.mobileS} { 
      width: 22px;
      height: 22px;
      font-size:15px;
    }
  }
}


`
export const MyAlbumRow = styled.div`
display: flex;
flex-wrap: wrap;
margin: 0 -10px;
> .col{
  padding: 0 10px;
  margin-bottom: 30px;
  flex: unset;
  width: 33.33%;
  @media ${device.lgdesktop} { 
     width: 25%;
  }
  @media ${device.tabletM} { 
    width: 50%;
  }
  @media ${device.mobileM} { 
    width: 100%;
  }
  > div{
    height: 100%;
  }
}
`
export const MyAlbumAdd = styled.div`
border: 1px solid #69747D;
box-sizing: border-box;
border-radius: 6px;
display: flex;
align-items: center;
justify-content: center;
box-shadow: none;
background: transparent;
width: 100%;
margin: 0;
padding: 50px 15px;
img{
  display: block;
  margin: auto;
}
@media ${device.laptop} { 
   
  }
  @media ${device.mobileB} { 
    width: 100%;
  }

`
export const MyAlbumTxt = styled.div`
text-align: center;

button{
    background: transparent;
    border: 1px solid #69747D;
    padding: 6px 18px;
    margin-top: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #69747D;
    font-size: 18px;
    cursor: pointer;
    transition: all 500ms ease;
    &:hover{
      color: #F8A66F;
      border-color: #F8A66F;
    }
}
@media ${device.laptop} { 
  font-size: 16px;
   }
@media ${device.tablet} { 
  font-size: 15px;
   }
`
export const MyAlbumaddImg = styled.img`
max-width: 100px;
`
export const MyAlbumList = styled.div`
box-shadow: 0px 20px 30px rgb(0 0 0 / 10%);
border-radius: 6px;
background: #fff;
transition: all 1s ease-in-out;
cursor: pointer;
width: 100%;
margin: 0;
@media ${device.minTablet}{
  &:hover {
    transform: translateY(-10px);
  }
}
@media ${device.laptop} { 
    width: 100%;
  }
  @media ${device.mobileB} { 
    width: 100%;
    margin: 0 0;
  }
`
export const MyAlbumImg = styled.img`
width: 100%;
`
export const MyAlbumDetails = styled.div`
padding: 16px 15px;
`
export const MyAlbumDetailsttl = styled.div`
padding: 0;
display: flex;
align-items: start;
img{
  margin-top: 0;
  transition: all 500ms ease;
  margin-left: auto;
  &:hover{
    opacity: 0.8;
  }
}
h2{
text-transform: uppercase;
font-size: 20px;
font-weight: 700;
margin-bottom: 0px;
flex: 1 1;
  @media ${device.lgScreen}{
    font-size: 24px;
  }
@media ${device.mobileB} { 
    font-size: 18px;
   }   
}
`
export const MyAlbumSetImg = styled.img`
width: 22px;
`
export const MyAlbumBottom = styled.div`
padding-top: 10px;
display: flex;
`
export const MyAlbumBottomTxt = styled.div`
padding-right: 15px;
display: flex;
align-items: center;
span{
    font-size: 15px;
    padding-left: 6px;
    display: block;
    line-height: 1;d
    @media ${device.lgScreen}{
      font-size: 18px;
    }
 
@media ${device.tabletM} { 
      font-size: 15px;
  } 
  @media ${device.mobileM} { 
    font-size: 13px;
   } 
   
}
`
export const MyAlbumBottomImg = styled.img`
width: 18px;
margin-top: -2px;
display: inline-block;
@media ${device.lgScreen}{
  width: 24px;
}
`