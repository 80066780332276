import styled from "styled-components";
import { size, device } from '../styles/Global';
export const InnerStyledHeader = styled.div`

width:100%;
.header_icons{
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 5;
    @media ${device.lgdesktop} { 
        top: 16px;
      }
}
`
export const Logo = styled.img`
width:100%
`
export const ShareIconBox = styled.div`
background: #fff;
display: flex;
padding: 12px;
box-shadow: 0px 5px 15px rgb(0 0 0 / 15%);
border-radius: 50px;
align-items: center;
position: ${props => props.visibility ? 'absolute': 'relative'};
top: ${props => props.visibility ? '-299%': '-35px'};
left: ${props => props.visibility ? '-50%': 'unset'};
transform:${props => props.visibility ? 'translateX(-50%)': 'unset'};
`
export const ShareBoxDiv = styled.div`
padding: 0px 10px
`
export const SocialShare = styled.img`
width: 30px;
`
export const CloseIcon = styled.img`
width: 16px;
`
export const PresentChartIcon = styled.img`
width: 30px;
`
export const UploadPhotoIcon = styled.img`
width: 30px;
`
export const ShareIcon = styled.img`
width: 30px;
`
export const SetingsIcon = styled.img`
width: 30px;
`
export const ProfileIcon = styled.img`
width: 30px;
`
export const ModalPassword = styled.div`
input{
width: 100%;
height: 47px;
border: 1px solid #283845;
padding: 0px 12px;
color: #283845;
font-size: 20px;
&::-webkit-input-placeholder {
    color: #283845;
    opacity: 1;
  }
  &:-ms-input-placeholder { 
    color: #283845;
    opacity: 1;
  }
  &::placeholder {
    color: #283845;
    opacity: 1;
  }
  @media ${device.mobileM} { 
    font-size: 16px;
}
}

label{
font-size: 15px;
padding-bottom: 5px;
line-height: 1.3;
@media ${device.mobileM} { 
    font-size: 14px;
}
}
`
export const UploadStatus = styled.div`
background: #fff;
width: 100%;
max-width: 302px;
position: fixed;
right: 30px;
z-index: 1000;
box-shadow: 0px 5px 15px rgb(0 0 0 / 15%);
border-radius: 12px;
padding: 18px;
bottom: ${props => props.bottom ? '20px': '-130px'};
transition: ease-in-out bottom 500ms;
.mobileBlck{
    display: none;
}
@media ${device.mobileM} { 
    bottom: ${props => props.bottom ? '0': '-130px'};
    max-width: 100%;
    border-radius: 0;
    left: 0;
    right: 0;
    button{
        display: block;
    }
    button.mobileBlck{
        display: block;
    }
}

`
export const UploadWrap = styled.div`
display: flex;
align-items: center;

p{
flex: 1 1;
font-size: 18px;
margin: 0px;
line-height: 1.3;
@media ${device.mobileM} { 
    font-weight: 700;
    font-size: 20px;
}
}
`
export const UploadProgress = styled.div`
display: flex;
width: 100%;
padding-top: 15px;
align-items:center;

span{
color: #F8A66F;
font-size: 16px;
font-weight: 700;
padding-right: 10px;
line-height: 1.3;
white-space: nowrap;
}
.ant-progress-outer {
    padding: 0px !important;
}
span.ant-progress-text {
    display: none;
}
.ant-progress-line {
    width: 100%;
}
`
export const UploadBtn = styled.div`
display: flex;
align-items: center;
button{
background: transparent;
border: navajowhite;
padding: 0  0 0 10px;
}
`
export const PausePlayIcon = styled.img`
width: 30px;
`
