import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import MyAlbum from './MyAlbum'
import UploadImages3 from './CreateAlbum/UploadImages3';
import Photographer from './PhotoGrapher/PhotoGrapher';
import SettingPage from './Settings/SettingPage';
import Header from '../components/Header'
import InnerHeader from './InnerHeader'
import {MainContainer} from './styles/Wrapper.styled'

const Createalbum = () => {
  let location = useLocation()
  let pageurl = location.pathname
  const { t } = useTranslation();
  return (
    <MainContainer>
        {pageurl == "/photographer"  || pageurl == "/settings" ?  <InnerHeader/>  : <Header/>}
          <Route path="/createalbum">
            <UploadImages3/>
          </Route>
          <Route path="/settings">
            <SettingPage/>
          </Route>
          <Route path="/photographer">
            <Photographer/>
          </Route>
          <Route path="/myalbum">
            <MyAlbum />
          </Route>
          <Route exact path="/">
          <UploadImages3/>
          </Route>
    </MainContainer>
  );
};
export default Createalbum;