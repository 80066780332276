import React, { useEffect, useState } from 'react';
import { guestList } from '../../data';
import { Container } from '../styles/Wrapper.styled';
import vector_dots from '../../assets/images/vector_dots.png';
import { Tooltip } from 'antd';
import 'antd/dist/antd.css';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Gauest_row_s, Gauest_item, Guest_wrap} from '../styles/Photographer.styled';


const GuestList = ({ parentCallback }) => {
    const [hide, setHide] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);

    const Hidesec = indx => {
        setHide(indx);
    };
    const texthide = <span className='whiteTool'><EyeInvisibleOutlined /> Hide Guest</span>;
    const textShow = <span className='whiteTool'><EyeOutlined /> Show Guest</span>;

   
    const [peopleInfo, setPeopleInfo] = useState([]);
    const toggleHandler = (item) => () => {
        setPeopleInfo((state) => ({
            ...state,
            [item.guestId]: state[item.guestId]
                ?''
                 :{
                    id: item.guestId,
                    photoUrl: item.photoUrl,
                }
        }));
       
    };
    useEffect(() => {
        if(localStorage.getItem("localpeopleInfo")){
            setPeopleInfo(JSON.parse(localStorage.getItem("localpeopleInfo")));
        }
    }, []);
    useEffect(() => {
        localStorage.setItem("localpeopleInfo", JSON.stringify(peopleInfo))
        parentCallback(peopleInfo)
    }, [peopleInfo]);

    window.onunload = function () {
        localStorage.clear();
    }

    return (
        <>
            <Container>
                <Gauest_row_s>
                    {guestList.map((guest) => {
                        return (
                            <Gauest_item key={guest.guestId}
                                className={guest.guestId === activeIndex ? "fadein" : ""}>
                                <Guest_wrap id={guest.guestId} className={guest.guestId === hide ? "fadeinimg" : ""}>
                                    <div className='guestChecklist'>
                                        <input type='checkbox' className='checkBX' name='guestList' onChange={toggleHandler(guest)}
                                            checked={peopleInfo[guest.guestId]} />
                                        <label className='showCntent' id='guestList'>
                                            <span className='borderBX'><img src={guest.photoUrl} alt="guestphoto" className='guestPhoto' /></span>
                                        </label>
                                        <span className='guesticonbtn'>
                                            <Tooltip placement="top" title={texthide} className="hidePop">
                                                <span><img src={vector_dots} alt="vectordots" onClick={() => Hidesec(guest.guestId)} /></span>
                                            </Tooltip>
                                            <Tooltip placement="top" title={textShow} className='d-none showAgn'>
                                                <span><img src={vector_dots} alt="vectordots" onClick={() => Hidesec(guest.guestId)} /></span>
                                            </Tooltip>
                                        </span>
                                    </div>
                                </Guest_wrap>
                            </Gauest_item>
                        )
                    })}
                </Gauest_row_s>
            </Container>
        </>
    );
};
export default GuestList;