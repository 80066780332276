import React from 'react';
import { photographerPhoto } from '../../data';
import { Container } from '../styles/Wrapper.styled';
import {Rows,GalleyPhotoItem} from '../styles/Photographer.styled';
import { useTranslation } from 'react-i18next';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Image } from 'antd';


const PhotoGrapherGallery = () => {
    const { t } = useTranslation();
    return (
        <>
            <Container>
                <Rows>
                    <Image.PreviewGroup>
                        <ResponsiveMasonry
                            columnsCountBreakPoints={{ 350: 1, 450: 2, 900: 3, 1200: 4, 1500: 5 }}
                        >
                            <Masonry>
                                {photographerPhoto.map((data) => (
                                    <GalleyPhotoItem key={data.photoId} className='pad10'>
                                        <Image src={data.photoUrl} alt="ceramony" />
                                    </GalleyPhotoItem>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </Image.PreviewGroup>
                </Rows>
            </Container>
        </>
    );
};

export default PhotoGrapherGallery;