import React, { useState, useRef } from 'react';
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3 } from "@aws-sdk/client-s3";
import { useHistory } from "react-router-dom";
import { Progress, DatePicker } from 'antd';
import addphoto from '../../assets/images/AddPhoto.png';
import '../../assets/css/createalbum.css';
import { useTranslation } from 'react-i18next';
import album1 from '../../assets/images/album1.png';
import album2 from '../../assets/images/album2.png';
import album3 from '../../assets/images/album3.png';
import { Drophoto, Right_sec_head, Upload_btn, AlbumDetail, Addcat, AlbumInput, EnterAlbumGalStyled, AddcatSection, AddDate, AddPhoto } from '../styles/UploadImages3.styled';
import { Container } from '../styles/Wrapper.styled';
import { RightSec, Rightwrap, UploadImg, Row, Back } from '../styles/Global';


const UploadImages3 = () => {
    let history = useHistory();
    const fileInput = useRef();
    const [progressInfos, setProgressInfos] = useState();
    const { t } = useTranslation();
    const [IsUploadBtn, setUploadBtn] = useState(false);
    const [IsDontHave, setDontHave] = useState(false);
    const [category, setCategory] = useState('');
    const [datein, setDatein] = useState('');
    const [visible, setVisible] = useState(true);
 
    const DontHave = () => {
        setDontHave(true)
    }
    // Step 2
    const [IsAddCat, setAddCat] = useState(false);

    const BackBtn = () => {
        setAddCat(false)
    }
    const goPhotography = () => {
        history.push("/photographer");
    }
    function onDate(date, dateString) {
        setDatein(date, dateString);
    }
    let count = 0

    const upload = (e) => {
        setUploadBtn(true);
        e.preventDefault();
        let newArr = fileInput.current.files;
          console.log(newArr,'newArr');
        for (let i = 0; i < newArr.length; i++) {
            var file = newArr[i]
            var target = { Bucket: "morethanpic", Key: file.name, Body: file };
            const creds = { accessKeyId: "AKIA2L4OBU6N5ASXUUJN", secretAccessKey: "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" }
            try {
                const parallelUploads3 = new Upload({
                    client: new S3Client({ region: "ap-south-1", credentials: creds }),
                    leavePartsOnError: false,
                    params: target,
                });
                parallelUploads3.on("httpUploadProgress", (progress) => {
                    console.log(progress, 'progress');
                    count++
                    let indexNum = (i + 1)
                    let progressPercentage = Math.round((100 * count) / newArr.length)
                    setProgressInfos(progressPercentage);
                });
                parallelUploads3.done();
            } catch (e) {
                console.log(e);
            }
        }
    }

    const UploadingPhoto = () => {
        return (
            <div>
                <EnterAlbumGalStyled>
                    <img src={album1} alt="album1" className="first" />
                    <img src={album2} alt="album2" className="sec" />
                    <img src={album3} alt="album3" className="third" />
                </EnterAlbumGalStyled>
                <div className='progressBar'>
                    <Progress percent={progressInfos} size="small" className="progressbar" />
                </div>
                <div className='d-flex align-items-center mt-1 mb-4'>
                  {progressInfos === 100 ? <span>Photos Uploaded</span>:<span>{t('UPLOADING_YOUR_PHOTOS')}</span>}
                    <AddPhoto className='mt-0 ml-auto mb-0'>
                        <input type="file" onChange={upload} multiple ref={fileInput} accept=".jpg, .jpeg, .png" />
                        <span> {t('ADD_PHOTOS')}</span>
                    </AddPhoto>
                </div>
            </div>
        )
    }
    const AlbumName = () => {
        const [albumName, setAlbumName] = useState('');
        const [IsCatError, setCatError] = useState("");
        const AddCat = () => {
            if (albumName === "") {
                setCatError("My Wedding field is required")
            } else {
                setAddCat(true)
            }
        }
        return (
            <div>
                <AlbumInput>
                    <input type="text" placeholder="My Wedding" value={albumName} onChange={e => setAlbumName(e.target.value)} />
                    <button type="submit" onClick={AddCat}></button>
                </AlbumInput>
                {IsCatError && <div className="errormsg">{IsCatError}</div>}
            </div>
        )
    }

    const AlbumCatDate = () => {
        return (
            <AlbumInput>
                <AddcatSection>
                    <label>{t('ALBUM_CATEGORY')}</label>
                    <select name="category" value={category} onChange={(e) => setCategory(e.target.value)}>
                        <option value="volvo">{t('CHOOSE_CATE')}</option>
                        <option value="saab">{t('WEDDING')}</option>
                    </select>
                </AddcatSection>
                <AddDate>
                    <label>{t('ALBUM_DATE')}</label>
                    <DatePicker onChange={onDate} value={datein} />
                </AddDate>
            </AlbumInput>
        )
    }
    return (
        <>
            <Drophoto>
                <Container>
                    <Row className="fullHt">
                        <RightSec out={!visible}>
                            <Rightwrap>
                                {IsDontHave ? <AlbumDetail>
                                    <UploadImg src={addphoto} alt="addalbum" />
                                    <h2 className='mt-5 mb-5'>{t('ENTER_ALBUM_DETAILS')}</h2>
                                    {IsAddCat ? <div>
                                        <AlbumCatDate />
                                        <button className="bg-gray" disabled={!category} onClick={goPhotography}>{t('CREATE_ALBUM')}</button>
                                        <Back onClick={BackBtn}>{t('BACK')}</Back>
                                    </div> :
                                        <Addcat>
                                            <label>{t('ALBUM_NAME')}</label>
                                            <AlbumName />
                                        </Addcat>}
                                </AlbumDetail> : (
                                    IsUploadBtn ?
                                        <div>
                                            <UploadingPhoto />
                                            <AlbumDetail>
                                                <h2>{t('ENTER_ALBUM_DETAILS')}</h2>
                                                {IsAddCat ? <div>
                                                    <AlbumCatDate />
                                                    <button className="bg-gray" disabled={!category} onClick={goPhotography}>{t('CREATE_ALBUM')}</button>
                                                    <Back onClick={BackBtn}>{t('BACK')}</Back>
                                                </div> :
                                                    <Addcat>
                                                        <label>{t('ALBUM_NAME')}</label>
                                                        <AlbumName />
                                                    </Addcat>}
                                            </AlbumDetail>
                                        </div> :
                                        <div>
                                            <UploadImg src={addphoto} alt="addalbum" />
                                            <Right_sec_head>
                                                <h1 data-role="tile" data-size="small">{t('CREATE_YOUR_ALBUM_IN_A_CLICK')}</h1>
                                                <h2>{t('DROP_PHOTOS_HERE')}</h2>
                                            </Right_sec_head>
                                            <Upload_btn>
                                                <input type="file" onChange={upload} multiple ref={fileInput} accept=".jpg, .jpeg, .png" />
                                                <span>{t('UPLOAD_PHOTOS')}</span>
                                            </Upload_btn>
                                            <p onClick={DontHave}>{t('I_DONT_HAVE_PHOTOS_YET')}</p>
                                        </div>
                                )}
                            </Rightwrap>
                        </RightSec>
                    </Row>
                </Container>
            </Drophoto>
        </>
    );
};

export default UploadImages3;