import React, { useState } from 'react';
import logo from '../assets/images/Logo.png';
import 'bootstrap/dist/css/bootstrap.css';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { BrowserRouter as Router, NavLink, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

const MainStyledHeader = styled.div`
width:100%;
a.active{
  color: #F8A66F !important;
  font-weight: bold;
  border: 0;
}
`
const Logo = styled.img`
width:100%
`
const Header = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const Openhumberger = () => {
    setOpen(!open);
  }
  const Overlay = () => {
    setOpen(false);
  }
  return (
    <MainStyledHeader>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <Logo src={logo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={Openhumberger} />
          <div className={open ? 'openMenu' : ''} id="overlayMenu" onClick={Overlay}></div>
          <div id="photomobhead" className={open ? 'openMenu' : ''} onClick={Overlay}>
            <ul className='row'>
              <li className="column"><Link to="/createalbum" onClick={Overlay}><p>Create Album</p></Link></li>
              <li className="column"><Link to="/myalbum" onClick={Overlay}><p>My Albums</p></Link></li>
              <li className="column"><Link to="/" onClick={Overlay}><p>Contact</p></Link></li>
            </ul>
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink exact activeClassName="active" to="/createalbum">{t('CREATE_ALBUM')}</NavLink>
              <NavLink exact activeClassName="active" to="/myalbum">{t('MY_ALBUMS')}</NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </MainStyledHeader>
  );
};
export default Header;