import React from "react";
import { albumData } from '../../data';
import { Container1366 } from '../styles/Wrapper.styled'
import {PhotoContent,CoupleImgSection,CoupleSecInner,CoupleWrap,CoupleHead} from '../styles/Photographer.styled';

const PhotographerTopImage = () => {
    return (
        <PhotoContent>
            <Container1366>
                {albumData.map((data) => {
                    if (data.id == 2) {
                        return (
                            <CoupleImgSection key={data.id}>
                                <CoupleSecInner>
                                    <img src={data.thumbnailUrl} alt="couple Image" />
                                </CoupleSecInner>
                                <CoupleWrap>
                                    <CoupleHead>
                                        <h1 className="diltoFnt">{data.name}</h1>
                                        <span>{data.date}</span>
                                        <p>{data.photographerName}</p>
                                    </CoupleHead>
                                </CoupleWrap>
                            </CoupleImgSection>
                        )
                    }
                })}
            </Container1366>
        </PhotoContent>
    );
};

export default PhotographerTopImage;