import React, { useState } from 'react';
import '../../assets/css/setting.css';
import General from './General';
import Permissions from './Permissions';
import Advanced from './Advanced';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

const SettingPage = () => {
    const { t } = useTranslation();
    let history = useHistory();
    const [general, setGeneral] = useState("general")

    const Photoback = () => {
        history.push("/photographer");
    }
    const genralpage = () => {
        setGeneral("general")
    }
    const Permipage = () => {
        setGeneral("permissions")
    }
    const advanced = () => {
        setGeneral("advance")
    }

    return (
        <>
            <div className='settings_section'>
                <div className='row_settings'>
                    <div className='settings_col_left'>
                        <div class="back" onClick={Photoback}>{t('BACK')}</div>
                        <h1>{t('ALBUM_SETTING')}</h1>
                        <ul className='settings_tabs'>
                            <li onClick={genralpage} className={general === "general" ? "active_set_tabs" : ""}>{t('GENERAL')}</li>
                            <li onClick={Permipage} className={general === "permissions" ? "active_set_tabs" : ""}>{t('PERMISSIONS')}</li>
                            <li onClick={advanced} className={general === "advance" ? "active_set_tabs" : ""}>{t('ADVANCED')}</li>
                        </ul>
                    </div>
                    <div className='settings_col_right'>
                        {general === "general" && <General />}
                        {general === "permissions" && <Permissions />}
                        {general === "advance" && <Advanced />}
                    </div>
                </div>
            </div>
        </>
    );
};
export default SettingPage;