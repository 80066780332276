import React, { useState } from 'react';
import { settingData } from '../../data';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Input } from 'antd';

const Permissions = () => {
    const { t } = useTranslation();
    const [isPublic, setPublic] = useState(false);
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');

    const validatepass = (value) => {
        if (validator.isStrongPassword(value, {
            Length: ' (?=.{8,}) ', Letters: '(?=.*[a-zA-Z])',
            Specialcharacters: '(?=.*[!#$%&? "])',
        })) {
            setPassword('Passwords is match')
        } else {
            setPassword('Please enter your password')
        }
    }
    const validateEmail = (email) => {
        if (validator.isEmail(email)) {
            setEmailError('Email is valid')
        } else {
            setEmailError('Enter valid Email!')
        }
    }

    return (
        <div className='albumSettings permissions_setting'>
            {settingData.map((data) => (
                <form className='genreal' id={data.id}>
                    <div className='field checkboxed'>
                        <input type="checkbox" placeholder={data.isPublic} value={isPublic} onChange={(e) => setPublic(true)} />
                        <span class="checkmark"></span>
                        <div className='labels_check'>
                            <p className='first_l'>{t('MAKE_ALBUM_PUBLIC')}</p>
                            <p className='sec_l'>{t('CHECK_THE_BOX_ALBUM')}</p>
                        </div>
                    </div>
                    <div className='field'>
                        <p className='first_l'>{t('ALBUM_ACCESS_PASSWORD')}</p>
                        <p className='sec_l'>{t('REQUIRE_PASSWORD')}</p>
                        <input type='password' placeholder="Album Password" onChange={(e) => validatepass(e.target.value)} />
                        <span className='errormsg'>{password}</span>
                    </div>
                    <div className='field'>
                        <p className='first_l'>{t('ALBUM_ADMIN_PERMISSIONS')}</p>
                        <p className='sec_l'>{t('EMAILS_LISTED')}</p>
                        <div className='perplus'>
                            <PlusCircleOutlined />
                            <input type="email" placeholder="example@gmail.com" onChange={(e) => validateEmail(e.target.value)} />
                        </div>
                        <span className='errormsg'>{emailError}</span>
                    </div>
                    {data.roles.map((roledata) => (
                        <div className='field selectboxes'>
                            <div className='selsect_labels'>
                                <p className='first_l'>{roledata.name}</p>
                                <p className='sec_l'> {roledata.emailAddress}</p>
                            </div>
                            <div className='select_f'>
                                <select className='select_cate_bx'>
                                    <option>{roledata.role}</option>
                                </select>
                            </div>
                        </div>
                    ))}
                    <div className='settings_btns'>
                        <button className="bg-gray" disabled={!isPublic || !password || !emailError}>{t('CANCEL')}</button>
                        <button disabled={!isPublic || !password || !emailError}>{t('SAVE_CHANGES')}</button>
                    </div>
                </form>
            ))}
        </div>
    );
};

export default Permissions;