import React, { useState } from 'react';
import { settingData } from '../../data';
import { useTranslation } from 'react-i18next';
import { Progress, DatePicker } from 'antd';

const General = () => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [date, setDate] = useState('');
    const [photograname, setPhotograname] = useState('');
    function onDate(date, dateString,e) {
        setDate(date, dateString);
    }

    return (
        <div className='albumSettings general_setting'>
            {settingData.map((data) => (
                <form className='genreal' key={data.id}>
                    <div className='field'>
                        <p className='first_l'>{t('ALBUM_NAME')}</p>
                        <p className='sec_l'>{t('THE_ALBUM_NAME_TO_BE')}</p>
                        <input placeholder={data.name} value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className='field'>
                        <p className='first_l'>{t('ALBUM_CATEGORY')}</p>
                        <p className='sec_l'>{t('CATEGORY_OF_THE_ALBUM')}</p>
                        <select onChange={(e) => setCategory(e.target.category)} value={category}>
                            <option value="volvo">{t('WEDDING')}</option>
                            <option value="saab">{t('WEDDING')}</option>
                        </select>
                    </div>
                    <div className='field'>
                        <p className='first_l'>{t('ALBUM_DATE')}</p>
                        <p className='sec_l'>{t('DATE_OF_THE_EVENT')}</p>
                        <DatePicker onChange={onDate} value={date}/>
                    </div>
                    <div className='field'>
                        <p className='first_l'>{t('PHOTOGRAPHER_NAME')}</p>
                        <p className='sec_l'>{t('PHOTOGRAPHER_NAME_TO_BE_DISPLAYED')}</p>
                        <input placeholder={data.photographerName} value={photograname} onChange={(e) => setPhotograname(e.target.value)} />
                    </div>
                    <div className='settings_btns'>
                        <button className="bg-gray" disabled={!name || !photograname}>{t('CANCEL')}</button>
                        <button disabled={!name || !photograname}>{t('SAVE_CHANGES')}</button>
                    </div>
                </form>
            ))}
        </div>
    );
};
export default General;