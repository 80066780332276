import React from 'react';
import addCircle from '../../src/assets/images/add-circle.png';
import gallery from '../../src/assets/images/gallery.png';
import eye from '../../src/assets/images/eye.png';
import calendar from '../../src/assets/images/calendar.png';
import settings from '../../src/assets/images/Settings.png';
import { useHistory } from "react-router-dom";
import { albumList } from '../data';
import { useTranslation } from 'react-i18next';
import { Container } from './styles/Wrapper.styled'
import {
    MyalbumStyled, MyAlbumRow, MyAlbumAdd, MyAlbumTxt, MyAlbumaddImg, MyAlbumList,
    MyAlbumImg, MyAlbumDetails, MyAlbumDetailsttl, MyAlbumSetImg, MyAlbumBottom, MyAlbumBottomTxt,
    MyAlbumBottomImg
} from './styles/MyAlbum.styled'

const Myalbum = () => {
    let history = useHistory();
    const { t } = useTranslation();
    function handleClick() {
        history.push("/createalbum");
    }
    function settingPage() {
        history.push("/settings");
    }
    return (
        <>
            <MyalbumStyled>
                <Container>
                    <h1>{t('MY_ALBUMS')} <span onClick={handleClick}><i>+</i> ADD PHOTO</span></h1>
                    <MyAlbumRow>
                        <div className="col">
                        <MyAlbumAdd onClick={handleClick}>
                            <MyAlbumTxt>
                                <MyAlbumaddImg src={addCircle} alt="addIcon" />
                                <button>{t('CREATE_NEW')}</button>
                            </MyAlbumTxt>
                        </MyAlbumAdd>
                        </div>
                        {albumList.map((data) => {
                            return (
                                <div className="col">
                                <MyAlbumList key={data.id}>
                                    <MyAlbumImg src={data.thumbnailUrl} onClick={()=>history.push("/photographer")}/>
                                    <MyAlbumDetails>
                                        <MyAlbumDetailsttl>
                                            <h2 onClick={()=>history.push("/photographer")}>{data.name}</h2>
                                            <MyAlbumSetImg onClick={settingPage} src={settings} />
                                        </MyAlbumDetailsttl>
                                        <MyAlbumBottom>
                                            <MyAlbumBottomTxt>
                                                <MyAlbumBottomImg src={calendar} alt="calendar" />
                                                <span>{data.date}</span>
                                            </MyAlbumBottomTxt>
                                            <MyAlbumBottomTxt>
                                                <MyAlbumBottomImg src={gallery} alt="gallery" />
                                                <span>{data.photosAmount}</span>
                                            </MyAlbumBottomTxt>
                                            <MyAlbumBottomTxt>
                                                <MyAlbumBottomImg src={eye} alt="eye" />
                                                <span>{data.viewsAmount}</span>
                                            </MyAlbumBottomTxt>
                                        </MyAlbumBottom>
                                    </MyAlbumDetails>
                                </MyAlbumList>
                                </div>
                            )
                        })}
                    </MyAlbumRow>
                </Container>
            </MyalbumStyled>
        </>
    );
};
export default Myalbum;