import React, { useState, useCallback } from "react";
import '../../assets/css/photographer.css';
import GuestCarousel from "./GuestCarousel";
import vector_clear from '../../assets/images/vector_clear.png';
import vector_dots from '../../assets/images/vector_dots.png';
import selfi from '../../assets/images/selfi.png';
import PhotographerTopImage from './PhotographerTopImage';
import PhotoGrapherGallery from './PhotoGrapherGallery';
import GuestGallery from './GuestGallery';
import GuestList from './GuestList';
import {
    Photogallery, Photogalleryinner, Guest_inner, Gallery_btns,
    Chng_selfieimg, Slider_wrappper, Slider_row, Slider_sideicon, ChangeSelfy_btn, Gallery_block, Footer_btn
} from '../styles/Photographer.styled';
import { useTranslation } from 'react-i18next';
import addpeople from '../../assets/images/addpeople.png';
import Footer from "../Footer";
import { Tooltip } from 'antd';
import 'antd/dist/antd.css';


const PhotoGrapher = () => {
    const [peopleInfo, setPeopleInfo] = useState([]);
    const callback = useCallback((peopleInfo) => {
        setPeopleInfo(peopleInfo);
    }, []);

    const text = <span className="d-block txtUnderline">Clear All</span>;
    const { t } = useTranslation();
    const [gallery, setPhotoGallery] = useState("PhotoGallery");
    // PhotoGallery
    const photogallery = () => {
        setPhotoGallery("PhotoGallery");
        if(find){
            setFind(true);
        }
    }

    // Guestgallery
    const guestgallery = () => {
        setPhotoGallery("Guestgallery");
        setAllclear(false);
        setFind(false);
        if(find){
            setFind(true);
        }
    }
    // GuestList
    const guestListbtn = () => {
        setPhotoGallery("GuestList");
        setAllclear(false);
        setFind(false);
        if(find){
            setFind(true);
        }
    }
    // Clear Btn
    const [allclear, setAllclear] = useState(false);
    const Clearall = () => {
        setAllclear(true);
    }
    console.log(allclear,'allclear')
    // dot btn
    const [dot, setDot] = useState(false);
    const Dotall = () => {
        if (gallery === "PhotoGallery" || gallery === "Guestgallery") {
            setPhotoGallery("GuestList");
        }
        setDot(true);
    }
    // Find yourself with selfi btn
    const [find, setFind] = useState(false);
    const Findselfie = () => {
        setFind(true);
        setPhotoGallery("PhotoGallery");

        if (gallery === "GuestList") {
            setAllclear(false);
            setPhotoGallery("GuestList");
        }
        if (gallery === "PhotoGallery") {
            setAllclear(false);
        }
    }
    // Back to gallry btn
    const Backtogal = () => {
        setPhotoGallery("PhotoGallery");
    }

    // selGuest btn
    const [selg, setSelg] = useState(false);
    const selGuest = () => {
        setSelg(true);
        if (gallery === "PhotoGallery") {
            setAllclear(false);
            setPhotoGallery("GuestList");
        }
        if (selg) {
            setAllclear(false);
            setFind(false);
            setPhotoGallery("GuestList");
        }
    }
    return (
        <>
            <PhotographerTopImage />
            <Photogallery>
                <Photogalleryinner className='photo_gallery_inner'>
                    <button onClick={photogallery} className={gallery === "PhotoGallery" ? "active_tabs" : ""}>
                        <p>{t('PHOTOGRAPHER_GALLERY')}</p>
                    </button>
                    <button onClick={guestgallery} className={gallery === "Guestgallery" ? "active_tabs" : ""}>
                        <p>{t('GUESTS_GALLERY')}</p>
                    </button>
                    <button onClick={guestListbtn} className={gallery === "GuestList" ? "active_tabs" : ""}>
                        <p>{t('GUESTS_LIST')}</p>
                    </button>
                </Photogalleryinner>
                <Gallery_block >
                    {allclear && gallery === "PhotoGallery" || allclear && gallery === "GuestList" ?
                        <Guest_inner>
                            {gallery === "GuestList" ?
                                <h1>{t('CLICK_ON_IMAGES_BELOW_TO_SELECT_GUESTS')}</h1> :
                                <h1>{t('THERE_ARE_NO_GUEST_SELECTED')}</h1>
                            }
                            <Gallery_btns className="photofind">
                                <button onClick={Findselfie}>{t('FIND_YOURSELF_WITH_SELFIE')}</button>
                                {
                                    gallery === "PhotoGallery" ?
                                        <button onClick={selGuest}>{t('SELECT_GUESTS')}</button> : null
                                }
                            </Gallery_btns>
                        </Guest_inner>
                        :
                        <Slider_wrappper>
                            <div className="rltvBox">
                                <Slider_row className="slider-row">
                                    <Slider_sideicon>
                                        <Tooltip placement="left" title={text}>
                                            <img src={vector_clear} alt="vectorclear" onClick={Clearall} className="d-none d-md-block" />
                                        </Tooltip>
                                        {gallery === "PhotoGallery" || gallery === "Guestgallery" || gallery === "GuestList" ? <span className="d-block d-md-none txtUnderline" onClick={Clearall} >Clear All</span> : ''}
                                    </Slider_sideicon>
                                    <GuestCarousel peopleInfo={peopleInfo} />
                                    {gallery === "PhotoGallery" || gallery === "Guestgallery" ?
                                        <Slider_sideicon onClick={Dotall}>
                                            <img src={vector_dots} alt="vectordots" className="d-none d-md-block" />
                                            <span className="d-flex align-items-center d-block d-md-none">
                                                <img src={addpeople} alt="vectordots" />
                                                <span className="txtUnderline">Show All Guests</span></span>
                                        </Slider_sideicon> : null}
                                </Slider_row>
                                <Gallery_btns className="justify-content-center pad10">
                                    {find ? '' :
                                        <button onClick={Findselfie}>{t('FIND_YOURSELF_WITH_SELFIE')}</button>
                                    }
                                    {find ?
                                        <Gallery_btns className="justify-content-center d-flex align-items-center mb-3">
                                            <Chng_selfieimg>
                                                <img src={selfi} />
                                            </Chng_selfieimg>
                                            <ChangeSelfy_btn>
                                                <button>{t('CHANGE_SELFIE')}</button>
                                            </ChangeSelfy_btn>
                                        </Gallery_btns>
                                        : ''
                                    }
                                </Gallery_btns>
                                {gallery === "GuestList" ? <button className="back" onClick={Backtogal}>{t('BACK_TO_GALLERY')}</button> : ''}
                            </div>
                        </Slider_wrappper>
                    }
                    {gallery === "PhotoGallery" ? <PhotoGrapherGallery /> : ''}
                    {gallery === "Guestgallery" ? <GuestGallery /> : ''}
                    {gallery === "GuestList" ? <GuestList parentCallback={callback} /> : ''}
                </Gallery_block>
            </Photogallery >
            <Footer />
        </>
    );
};
export default PhotoGrapher;