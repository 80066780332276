import React from 'react';
import { device } from '../components/styles/Global';
import Logofooter from '../assets/images/Logofooter.png';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

const FooterStyled = styled.div`
width:100%
span{
    padding: 0px 8px;
}
`
const FooterInner = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding : 15px 0 30px;
font-size: 18px;
color: #283845;
background: #fff;
@media ${device.mobileB} { 
    font-size: 16px;
      }
`
const FooterLogo = styled.img`
margin-left: 10px;
max-height: 20px;
`
const Footer = () => {
    const { t } = useTranslation();

    return (
        <FooterStyled>
            <FooterInner>
                <span>{t('POWERED_BY')}</span>
                <span><FooterLogo src={Logofooter} alt="logo" /></span>
            </FooterInner>
        </FooterStyled>
    );
};
export default Footer;