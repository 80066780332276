import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { albumData, guestList } from '../../data';
import styled from "styled-components";
import { device } from '../styles/Global';

// Crousel for all gallerys
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 10
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 8
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};

const GuestSlide = styled.div`
position: relative;
padding: 0px 83px;
@media ${device.mobileB} { 
    padding: 0px;
    margin-bottom: 60px;
  }
.react-multi-carousel-list{
    position: static;    
}
.crousel-slide{
    justify-content:center;
}
`
const GuestSlideItem = styled.div`
margin: 0 10px;
position:relative;
@media ${device.mobileB} { 
    margin: 0 4px;
  }
  .checkBXcrousel{
    position: absolute;
    inset: 0px;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 1;}
  .checkBXcrousel +  .borderBX{
    border: 3px solid transparent;
    filter: grayscale(100%);
 }
 .checkBXcrousel:hover +  .borderBX{
    filter: grayscale(0);
 }
 .checkBXcrousel:checked +  .borderBX{
    border: 3px solid rgb(248, 166, 111);
    filter: grayscale(0);
 }
 
`
const GuestSlideImg = styled.img`
width: 100%;
`

const GuestCarousel = ({ peopleInfo }) => {
    var obj = peopleInfo;
    console.log(obj, 'obj')
    var res = [];
    for (var i in obj)
        res.push(obj[i]);
    const filterArray = (res, guestList) => {
        const filtered = res.filter(el => {
            return guestList.indexOf(el) === -1;
        });
        return filtered;
    };
    var newArray = (filterArray(res, guestList));
    var temp = [];
    for (let i of newArray)
        i && temp.push(i);
    newArray = temp;
console.log(newArray.length)
    return (
        <GuestSlide>
            {newArray.length > 0 ? <Carousel responsive={responsive} className={newArray.length > 0 ? "crousel-slide" : ''}
                draggable={false}>
                {newArray.map((guest) =>{
                    return (<GuestSlideItem id={guest.id}>
                        <div className='guestChecklist'>
                            <input type='checkbox' className='checkBXcrousel' name='guestList'/>
                            <div className='borderBX'>
                                <GuestSlideImg src={guest.photoUrl} alt="guestphoto"/>
                            </div>
                        </div>
                    </GuestSlideItem>
                    )
                })}
            </Carousel> :
                <Carousel responsive={responsive}
                    draggable={false} >
                    {guestList.map((guest) => {
                        return (
                            <GuestSlideItem key={guest.guestId} >
                                <div className='guestChecklist'>
                                    <input type='checkbox' className='checkBXcrousel' name='guestList' />
                                    <div className='borderBX'>
                                        <GuestSlideImg src={guest.photoUrl} alt="guestphoto" />
                                    </div>
                                </div>
                            </GuestSlideItem>
                        )
                    })}
                </Carousel>
            }
        </GuestSlide>
    );
};
export default GuestCarousel;